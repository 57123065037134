import React from "react";
import styles from "./layout.module.scss";
import { HeaderHome } from "../Shared/HeaderHome";
interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className={styles.container}>
      <HeaderHome/>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Layout;
