import React, { FC } from "react";
import styles from "./containerTovy.module.scss";
import logoChat from "../../../../../assets/pages/chaside/chat/logoChat.png";
import { PropsMessage } from "../../utils";

interface PropsContainerTovy {
  message: PropsMessage;
}
const ContainerTovy: FC<PropsContainerTovy> = ({ message }) => {
  return (
    <div className={styles.containerAI}>

      <img src={logoChat} alt="Profile" className={styles.circleImg} />

      <div className={styles.containerTextAi}>
        <div className={styles.textAI}>{message.message}</div>
      </div>
    </div>
  );
};

export default ContainerTovy;
